import React from "react";
import { Route, Routes } from "react-router-dom";

import NavBar from "./components/Navbar";
import Home from "./Pages/Home";
import Home2 from "./Pages/Home2";
import FormPage from "./Pages/FormPage";
import Login from "./Pages/Login";
import FormPage2 from "./Pages/FormPage2";
import Homepage from "./Pages/Homepage";
import UseAuth from "./hooks/UseAuth";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <UseAuth>
        {" "}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<NavBar />}>
            <Route path="/anasayfa" element={<Homepage />} />
            <Route path="/duyuru" element={<Home />} />
            <Route path="/referans" element={<Home2 />} />
            <Route path="form" element={<FormPage />} />
            <Route path="form2" element={<FormPage2 />} />
          </Route>
          {/* <Route path='/' element={<Home/>}/> */}
        </Routes>
        <Footer />
      </UseAuth>
    </div>
  );
}

export default App;
