import React, { useState, useEffect } from "react";
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CWidgetStatsA,
} from "@coreui/react";
import { FormApi } from "../api/form_api";
import { CChartLine } from "@coreui/react-chartjs";
import CIcon from "@coreui/icons-react";
import { cilArrowTop } from "@coreui/icons";
const endpoint = "duyuru";
function WidgetsDropdown(props) {
  const formApi = new FormApi(endpoint);

  const res = formApi.getPage();

  return (
    <div className="row d-flex justify-content-center">
      <CRow className="d-flex justify-content-center">
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4 "
            style={{ background: "#23438c", color: "white" }}
            value={
              <>
                7{" "}
                <span className="fs-6 fw-normal">
                  ( <CIcon icon={cilArrowTop} />)
                </span>
              </>
            }
            title="Ürün"
            action={
              <CDropdown alignment="end">
                <CDropdownToggle
                  color="transparent"
                  caret={false}
                  className="p-0"
                ></CDropdownToggle>
              </CDropdown>
            }
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels: ["", "", "", "", "", "", ""],
                  datasets: [
                    {
                      backgroundColor: "rgba(255,255,255,.2)",
                      borderColor: "rgba(255,255,255,.55)",
                      data: [78, 81, 80, 45, 34, 12, 40],
                      fill: true,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
      </CRow>
    </div>
  );
}

export default WidgetsDropdown;
