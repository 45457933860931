import React from "react";

function HomePage() {
  return (
    <div className="p-3">
      <div className="homepage">
        <div class="container-2">
          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>Duyuru</h2>
                <h3>Duyuru</h3>

                <p>
                  Duyurular sayfanızla ilgili tüm düzenlemelerinizi bu bölümde
                  yapabilirsiniz.
                </p>
                <a href="/duyuru">Giriş</a>
              </div>
            </div>
          </div>

          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>Referans</h2>
                <h3>Referans</h3>
                <p>
                  Referans sayfanızla ilgili tüm düzenlemelerinizi bu bölümde
                  yapabilirsiniz.
                </p>
                <a href="/referans">Giriş</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default HomePage;
