import React, { useState, useEffect } from "react";
import { Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Paginator from "../components/Paginator";
import WidgetsDropdown from "../components/WidgetsDropdown";
import alertify from "alertifyjs";
import Zoomer from "../components/Zoom2";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../api/form_api";
import ReactHtmlParser from "react-html-parser";

const endpoint = "duyuru";

function Home() {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [productDetail, setProductDetail] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/form", { state: { product } });
  };
  const deleteProduct = (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product.duyuruNo} nolu duyuruyu silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id, img: product.img };
          const result = await formApi.deleteImage(obj);
          if (result) alertify.success("Referans silindi..", 2.5);
        } catch (e) {
          alertify.error("Referans silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const showDetail = (product) => {
    setProductDetail(product);
    toggle();
  };
  const loadPage = (products) =>
    products.map((product) => (
      <tr
        class="align-middle text-center"
        key={product.duyuruNo}
        onClick={(e) => showDetail(product)}
      >
        <td>
          <img
            src={`https://apirest.grafennokta.com/uploads/${product.img}`}
            height={50}
          />
        </td>
        <td>
          <img
            src={`https://apirest.grafennokta.com/uploads/${product.img_en}`}
            height={50}
          />
        </td>
        <td>{product.duyuruNo}</td>

        <td class="align-middle">
          {ReactHtmlParser(product.info.slice(0, 50))}
        </td>
        <td class="align-middle">
          {ReactHtmlParser(product.info_en.slice(0, 50))}
        </td>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  const onSearchHandler = (e) => {
    e.preventDefault();
    const filteredProducts = [];
    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (key === "name" && value.toLowerCase().includes(search))
          filteredProducts.push(product);
      });
    });
    setTempProducts(loadPage(filteredProducts));
  };
  useEffect(() => {
    (async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    })();
  }, []);

  return (
    <div className="main p-3">
      <Zoomer toggle={toggle} modal={modal} product={productDetail} />
      <div className="d-flex justify-content-between p-3">
        <Button
          style={{ background: "black" }}
          size="sm"
          onClick={() => navigate("/form")}
        >
          Duyuru Ekle
        </Button>
        <form className="d-flex">
          <Input
            type="search"
            name="search"
            id="exampleSearch"
            placeholder="Başlığa göre arayın..."
            value={search}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                setProducts(productsAll);
                setTempProducts(loadPage(productsAll));
              } else {
                let filteredProducts = [];
                products.forEach((product) => {
                  Object.entries(product).forEach(([key, value]) => {
                    if (
                      key === "name" &&
                      value.toLowerCase().includes(e.target.value.toLowerCase())
                    )
                      filteredProducts.push(product);
                  });
                });
                setProducts(filteredProducts);
                setTempProducts(loadPage(filteredProducts));
              }
              setSearch(e.target.value);
            }}
          />
          <Button
            type="submit"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              background: "black",
            }}
            onClick={(e) => onSearchHandler(e)}
          >
            Ara
            <i className="fa fa-search"></i>
          </Button>
        </form>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="table-scroll col-12">
            <Table striped bordered hover style={{ background: "white" }}>
              <thead>
                <tr className="text-bold text-center">
                  <th>Görsel(tr)</th>
                  <th>Görsel(en)</th>
                  <th>Referans No</th>

                  <th>Açıklama (tr)</th>
                  <th>Açıklama (en)</th>
                  <th>Seçenekler</th>
                </tr>
              </thead>
              <tbody>
                {tempProducts.length === 0 ? (
                  <p className="text-danger p-2">Referans bulunamadı</p>
                ) : (
                  tempProducts.slice(0, 10)
                )}
              </tbody>
            </Table>
            {products ? (
              <Paginator
                len={products.length}
                data={products}
                setTempProducts={setTempProducts}
                loadPage={loadPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
