import React from "react";
import { ImExit } from "react-icons/im";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import alertify from "alertifyjs";
import Indicator from "./Indicator";
import { Outlet } from "react-router-dom";
import logo from "../img/logo_black.png";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onLogOut = this.onLogOut.bind(this);
    this.state = {
      isOpen: false,
      isLogOut: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  onLogOut(e) {
    this.setState({ isLogOut: true });
    setTimeout(() => {
      window.location.href = "/login";
      localStorage.removeItem("asdf");
    }, 2000);
    alertify.error("Çıkış yapılıyor...", 2.5);
  }
  render() {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <a className="mt-5" href="/anasayfa">
            <img src={logo}></img>
          </a>
        </div>
        <Navbar color="light" light expand="md">
          <NavbarBrand></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="w-100 justify-content-end " navbar>
              <div className="p-2">
                <div className="exit-icon p-2">
                  <ImExit onClick={(e) => this.onLogOut(e)} />
                  {this.state.isLogOut === true ? <Indicator /> : "    "}
                </div>
              </div>
            </Nav>
          </Collapse>
        </Navbar>
        <Outlet />
      </div>
    );
  }
}
