import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { lengthError, typeError } from "../form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import Slider from "../components/Slider";
import { useLocation } from "react-router-dom";
import { FormApi } from "../api/form_api";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const dataSources2 = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "duyuru";
function FormPage() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    duyuruNo: "",
    info: "",
    name: "",
    info_en: "",
    img: "",
    img_en: "",
  });
  const [validation, setValidation] = useState({
    duyuruNo: "",
    info: "",
    name: "",
    info_en: "",
    img: "",
    img_en: "",
  });

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("img", form.img);
    formData.append("img_en", form.img_en);
    formData.append("name", form.name);
    formData.append("duyuruNo", form.duyuruNo);
    formData.append("info", form.info);
    formData.append("info_en", form.info_en);

    try {
      const rst = await formApi.addPage(formData);
      alertify.success(
        "Duyuru eklendi..",
        ((window.location.href = "/duyuru"), 1000),
        2.5
      );
    } catch (e) {
      alertify.error("Duyuru eklenemedi..", 1000);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      alertify.error("Düzenlemeler yapılamadı..", 1000);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("img", form.img);
      formData.append("img_en", form.img_en);
      formData.append("name", form.name);
      formData.append("duyuruNo", form.duyuruNo);
      formData.append("info", form.info);
      formData.append("info_en", form.info_en);

      try {
        const rst = await formApi.updatePage(formData);
        alertify.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/duyuru"), 1000),
          2.5
        );
      } catch (e) {
        alertify.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
      });
  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };
  const imageUploadEn = (data) => {
    setForm((prevState) => ({ ...prevState, ["img_en"]: data }));
  };

  return (
    <div className="container mt-3 pb-5">
      <h4>Duyuru Ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        <Label>
          <b>Fotoğraflar</b>
        </Label>

        <Slider imageUpload={imageUpload} data={dataSources} />
        <Label>
          <b>Fotoğraflar(en)</b>
        </Label>

        <Slider imageUpload={imageUploadEn} data={dataSources2} />
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="duyuruNo">
                <b>Duyuru Numarası</b>
              </Label>
              <Input
                id="duyuruNo"
                name="duyuruNo"
                placeholder="Lütfen duyuru numarasını giriniz..."
                type="number"
                valid={validation.duyuruNo === "T"}
                invalid={validation.duyuruNo === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.duyuruNo}
                min={0}
              />
              {lengthError}
            </FormGroup>
            <FormGroup>
              <Label for="name">
                <b>Duyuru Adı</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen duyuru adını  giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
                valid={validation.name === "T"}
                invalid={validation.name === "F"}
              />
              {lengthError}
            </FormGroup>
          </div>
        </div>

        <Label for="info">
          <b>Açıklama(tr)</b>
        </Label>
        <CKEditor
          name="info"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.info !== "") editor.setData(form.info);
          }}
          onChange={(event, editor) =>
            onChangeHandler("info", editor.getData())
          }
        />
        {validation.info === "F" && (
          <div className="val_message">Bu alan boş bırakılamaz.</div>
        )}
        <Label for="info">
          <b>Açıklama(en)</b>
        </Label>
        <CKEditor
          name="info_en"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.info_en !== "") editor.setData(form.info_en);
          }}
          onChange={(event, editor) =>
            onChangeHandler("info_en", editor.getData())
          }
        />
        {validation.info_en === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}
        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>
        <Button
          type="submit"
          style={{
            background: "white",
            color: "black",
          }}
          onClick={(e) => onUpdateHandler(e)}
        >
          Güncelle
        </Button>
      </Form>
    </div>
  );
}

export default FormPage;
